var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "ul",
    {
      staticClass: "pagination m-0",
    },
    [
      _c(
        "li",
        {
          class: {
            disabled: _vm.isOnFirstPage,
          },
        },
        [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.loadPage(1)
                },
              },
            },
            [_c("span", [_vm._v("«")])]
          ),
        ]
      ),
      _c(
        "li",
        {
          class: {
            disabled: _vm.isOnFirstPage,
          },
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: "#",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage("prev")
                },
              },
            },
            [_c("span", [_vm._v(" ‹")])]
          ),
        ]
      ),
      _vm.notEnoughPages
        ? _vm._l(_vm.totalPage, function (n, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  active: _vm.isCurrentPage(n),
                },
              },
              [
                _c("a", {
                  attrs: {
                    href: "#",
                  },
                  domProps: {
                    innerHTML: _vm._s(n),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.loadPage(n)
                    },
                  },
                }),
              ]
            )
          })
        : _vm._l(_vm.windowSize, function (n, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  active: _vm.isCurrentPage(_vm.windowStart + n - 1),
                },
              },
              [
                _c("a", {
                  attrs: {
                    href: "#",
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.windowStart + n - 1),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.loadPage(_vm.windowStart + n - 1)
                    },
                  },
                }),
              ]
            )
          }),
      _c(
        "li",
        {
          class: {
            disabled: _vm.isOnLastPage,
          },
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: "#",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage("next")
                },
              },
            },
            [_c("span", [_vm._v("› ")])]
          ),
        ]
      ),
      _c(
        "li",
        {
          class: {
            disabled: _vm.isOnLastPage,
          },
        },
        [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.loadPage(_vm.totalPage)
                },
              },
            },
            [_c("span", [_vm._v("»")])]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }